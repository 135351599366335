<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        icon
        v-on="on"
        style="height: 30px; margin-top: 5px"
        @click="
          $refs.FormSupplier.fetchProductBrand() != null
            ? $refs.FormSupplier.fetchProductBrand()
            : '';
          open();
        "
      >
        <v-icon v-if="small" small>mdi-plus-circle</v-icon>
        <v-icon v-else size="40">mdi-plus-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("inventorys.new_supplier") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <supplier-form
          ref="FormSupplier"
          :title="$t('inventorys.new_supplier')"
          :supplier="supplier"
          @validated="saveSupplier()"
          @back="dialog = false"
        ></supplier-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import Btn from "../pages/components/Btn.vue";
//import constants from "@/constants";
export default {
  name: "SupplierNew",
  props: ["small"],
  components: { SupplierForm: () => import("@/components/supplier/Form") },
  data() {
    return {
      supplier: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("supplier", ["addSupplier"]),

    saveSupplier() {
      //console.log(this.supplier);
      this.addSupplier({ supplier: this.supplier }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("supplier_create_success"), "", "");
          this.dialog = false;
          this.$emit("save");
        }
      });
    },
    open() {
      this.supplier = {};
    },
  },
};
</script>
